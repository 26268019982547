/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../_metronic/_helpers";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/* Components */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">MANAGE</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}

        <li
          className={`menu-item ${getMenuItemActive("/Customer")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/Customer">
            <i className="menu-bullet menu-bullet-dot">
              <span />
            </i>
            <span className="menu-text">Customer</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive("/ProductCategory")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/ProductCategory">
            <i className="menu-bullet menu-bullet-dot">
              <span />
            </i>
            <span className="menu-text">Product Category</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive("/ProductType")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/ProductType">
            <i className="menu-bullet menu-bullet-dot">
              <span />
            </i>
            <span className="menu-text">Product Type</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive("/ServiceTeam")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/ServiceTeam">
            <i className="menu-bullet menu-bullet-dot">
              <span />
            </i>
            <span className="menu-text">Service Team</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive("/WorkCaseType")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/WorkCaseType">
            <i className="menu-bullet menu-bullet-dot">
              <span />
            </i>
            <span className="menu-text">Work Case Type</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive("/WorkCase")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/WorkCase">
            <i className="menu-bullet menu-bullet-dot">
              <span />
            </i>
            <span className="menu-text">Work Case</span>
          </NavLink>
        </li>
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
