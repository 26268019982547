import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { DashboardPage } from "./pages/DashboardPage";
import { ProductCategory } from "./pages/ProductCategory";
import { CreateProductCategory } from "./pages/component/CreateProductCategory";
import { EditProductCategory } from "./pages/component/EditProductCategory";
import { ProductType } from "./pages/ProductType";
import { CreateProductType } from "./pages/component/CreateProductType";
import { EditProductType } from "./pages/component/EditProductType";
import { ServiceTeam } from "./pages/ServiceTeam";
import { CreateServiceTeam } from "./pages/component/CreateServiceTeam";
import { EditServiceTeam } from "./pages/component/EditServiceTeam";
import { Customer } from "./pages/Customer";
import { CreateCustomer } from "./pages/component/CreateCustomer";
import { EditCustomer } from "./pages/component/EditCustomer";
import { WorkCase } from "./pages/WorkCase";
import { CreateWorkCase } from "./pages/component/CreateWorkCase";
import { EditWorkCase } from "./pages/component/EditWorkCase";
import { WorkCaseType } from "./pages/WorkCaseType";
import { CreateWorkCaseType } from "./pages/component/CreateWorkCaseType";
import { EditWorkCaseType } from "./pages/component/EditWorkCaseType";
export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {<Redirect exact from="/" to="/dashboard" />}
        <ContentRoute path="/Dashboard" component={DashboardPage} />

        <ContentRoute path="/Customer" component={Customer} />
        <ContentRoute path="/ProductCategory" component={ProductCategory} />
        <ContentRoute path="/ProductType" component={ProductType} />
        <ContentRoute path="/ServiceTeam" component={ServiceTeam} />
        <ContentRoute path="/WorkCase" component={WorkCase} />
        <ContentRoute path="/WorkCaseType" component={WorkCaseType} />

        <ContentRoute path="/CreateProductCategory" component={CreateProductCategory} />
        <ContentRoute path="/CreateProductType" component={CreateProductType} />
        <ContentRoute path="/CreateServiceTeam" component={CreateServiceTeam} />
        <ContentRoute path="/CreateCustomer" component={CreateCustomer} />
        <ContentRoute path="/CreateWorkCase" component={CreateWorkCase} />
        <ContentRoute path="/CreateWorkCaseType" component={CreateWorkCaseType} />

        <ContentRoute path="/EditProductCategory" component={EditProductCategory} />
        <ContentRoute path="/EditProductType" component={EditProductType} />
        <ContentRoute path="/EditServiceTeam" component={EditServiceTeam} />
        <ContentRoute path="/EditCustomer" component={EditCustomer} />
        <ContentRoute path="/EditWorkCase" component={EditWorkCase} />
        <ContentRoute path="/EditWorkCaseType" component={EditWorkCaseType} />

        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
