import React, { useState, useEffect } from "react";
import { useSubheader } from "../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../_metronic/_partials/controls";
import { useHistory } from "react-router-dom";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { baseURL } from "../../service/API.js";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import axios from "axios";
import Switch from "@material-ui/core/Switch";
import Swal from "sweetalert2";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../_metronic/_helpers";
import paginationFactory from "react-bootstrap-table2-paginator";
import Select from "react-select";
import { Link } from "react-router-dom";

export const EditWorkCaseType = (props) => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Edit Work Case Type");
  const history = useHistory();

  const [id, setId] = useState();
  const [name, setName] = useState();

  useEffect(() => {
    setId(props.location.state.row.id);
    setName(props.location.state.row.name);
  }, []);

  const AlertError = (message) => {
    Swal.fire("Work Case Type!", message, "error");
  };

  const onSubmit = async (data) => {
    if (name === undefined || name === "") {
      return AlertError("please insert name.");
    }

    var events = {
      name: name,
    };
    axios
      .put(`${baseURL}/workCaseType/${id}`, events)
      .then((res) => {
        history.goBack();
      })
      .catch((err) => {
        Swal.fire(
          "Edit !",
          "Your can not update WorkCaseType. !! " + err,
          "error"
        );
      });
  };

  return (
    <>
      <Card>
        <CardHeader title="Edit Work Case Type"></CardHeader>
        <CardBody>
          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              Name:{" "}
            </p>
            <input
              name="name"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={name}
              onChange={(name) => setName(name.target.value)}
            />
          </div>

          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <div
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            ></div>
            <Link to="/WorkCaseType">
              <button type="button" className="btn btn-secondary wbt mr-5">
                Cancel
              </button>
            </Link>
            <button className="btn btn-primary" onClick={() => onSubmit()}>
              Save
            </button>
          </div>
        </CardBody>
      </Card>
    </>
  );
};
