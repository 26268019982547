import React, { useState, useEffect } from "react";
import { useSubheader } from "../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../_metronic/_partials/controls";
import { useHistory } from "react-router-dom";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import axios from "axios";
import { baseURL } from "../../service/API.js";
import Switch from "@material-ui/core/Switch";
import Swal from "sweetalert2";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../_metronic/_helpers";
import paginationFactory from "react-bootstrap-table2-paginator";
import Select from "react-select";
import { Link } from "react-router-dom";

export const CreateServiceTeam = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Create Service Team");
  const history = useHistory();

  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();

  const AlertError = (message) => {
    Swal.fire("Service Team!", message, "error");
  };

  const onSubmit = async (data) => {
    if (firstName === undefined || firstName === "") {
      return AlertError("please insert first name.");
    }

    if (lastName === undefined || lastName === "") {
      return AlertError("please insert last name.");
    }

    if (username === undefined || username === "") {
      return AlertError("please insert username.");
    }

    if (password === undefined || password === "") {
      return AlertError("please select password.");
    }

    var events = {
      first_name: firstName,
      last_name: lastName,
      username: username,
      password: password,
    };
    axios
      .post(`${baseURL}/serviceTeam`, events)
      .then((res) => {
        history.goBack();
      })
      .catch((err) => {
        Swal.fire(
          "Create !",
          "Your can not update serviceTeam. !! " + err,
          "error"
        );
      });
  };

  return (
    <>
      <Card>
        <CardHeader title="Create Service Team"></CardHeader>
        <CardBody>
          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              First name:{" "}
            </p>
            <input
              name="first_name"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              onChange={(first_name) => setFirstName(first_name.target.value)}
            />
          </div>

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              Last name:{" "}
            </p>
            <input
              name="last_name"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              onChange={(last_name) => setLastName(last_name.target.value)}
            />
          </div>

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              Username:{" "}
            </p>
            <input
              name="username"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              onChange={(username) => setUsername(username.target.value)}
            />
          </div>

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              Password:{" "}
            </p>
            <input
              name="password"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              onChange={(password) => setPassword(password.target.value)}
            />
          </div>

          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <div
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            ></div>
            <Link to="/serviceTeam">
              <button type="button" className="btn btn-secondary wbt mr-5">
                Cancel
              </button>
            </Link>
            <button className="btn btn-primary" onClick={() => onSubmit()}>
              Save
            </button>
          </div>
        </CardBody>
      </Card>
    </>
  );
};
