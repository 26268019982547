import React, { useState, useEffect } from "react";
import { useSubheader } from "../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../_metronic/_partials/controls";
import { useHistory } from "react-router-dom";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { baseURL } from "../../service/API.js";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import axios from "axios";
import Switch from "@material-ui/core/Switch";
import Swal from "sweetalert2";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../_metronic/_helpers";
import paginationFactory from "react-bootstrap-table2-paginator";
import Select from "react-select";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Modal from "@material-ui/core/Modal";

export const EditCustomer = (props) => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Edit Customer");
  const history = useHistory();

  const [id, setId] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [userId, setUserId] = useState();
  const [mobile, setMobile] = useState();
  const [password, setPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [openCheck, setOpenCheck] = useState(false);
  const allData = [];

  useEffect(() => {
    console.log(props.location.state.row);
    setId(props.location.state.row.id);
    setFirstName(props.location.state.row.first_name);
    setLastName(props.location.state.row.last_name);
    setUserId(props.location.state.row.user_id);
    setMobile(props.location.state.row.mobile);
  }, []);

  const AlertError = (message) => {
    Swal.fire("Customer!", message, "error");
  };

  const onSubmit = async (data) => {
    if (firstName === undefined || firstName === "") {
      return AlertError("please insert first name.");
    }

    if (lastName === undefined || lastName === "") {
      return AlertError("please insert last name.");
    }

    if (userId === undefined || userId === "") {
      return AlertError("please insert userId.");
    }

    if (password === undefined || password === "") {
      return AlertError("please select password.");
    }

    if (mobile === undefined || mobile === "") {
      return AlertError("please select mobile.");
    }

    var events = {
      first_name: firstName,
      last_name: lastName,
      mobile: mobile,
      user_id: userId,
      full_name: firstName + " " + lastName,
    };
    axios
      .put(`${baseURL}/customer/${id}`, events)
      .then((res) => {
        history.goBack();
      })
      .catch((err) => {
        Swal.fire("Edit !", "Your can not update customer. !! " + err, "error");
      });
  };

  const handleClose = () => {
    setOpenCheck(false);
  };

  const handleSubmitPassword = async (values, { setSubmitting }) => {
    await setSubmitting(false);
    await editPassword(values);
    return;
  };

  const editPassword = async (values) => {
    let data = {
      password: values.password,
      newPassword: values.newpassword,
    };
    if (data.password && data.newPassword) {
      axios
        .put(
          `${baseURL}/customer/${props.location.state.row.id}/password`,
          data
        )
        .then((res) => {
          if (res.data.status === 200) {
            handleClose();
            Swal.fire("Update Password", "Update password success.");
          } else {
            alert("Password don't match  !");
          }
        })
        .catch((err) => {
          alert("Password don't match  !");
        });
    }
  };

  return (
    <>
      <Card>
        <CardHeader title="Edit Customer"></CardHeader>
        <CardBody>
          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              First name:{" "}
            </p>
            <input
              name="first_name"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={firstName}
              onChange={(first_name) => setFirstName(first_name.target.value)}
            />
          </div>

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              Last name:{" "}
            </p>
            <input
              name="last_name"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={lastName}
              onChange={(last_name) => setLastName(last_name.target.value)}
            />
          </div>

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              User id:{" "}
            </p>
            <input
              name="userId"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={userId}
              onChange={(userId) => setUserId(userId.target.value)}
            />
          </div>

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              Mobile:{" "}
            </p>
            <input
              name="mobile"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={mobile}
              onChange={(mobile) => setMobile(mobile.target.value)}
            />
          </div>

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              Password:{" "}
            </p>
            <a
              style={{
                textDecoration: "#007BFF underline",
                color: "#007BFF",
              }}
              data-toggle="modal"
              data-target="#modal-EditPassword"
              onClick={() => setOpenCheck(true)}
            >
              Change Password
            </a>
          </div>

          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <div
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            ></div>
            <Link to="/customer">
              <button type="button" className="btn btn-secondary wbt mr-5">
                Cancel
              </button>
            </Link>
            <button className="btn btn-primary" onClick={() => onSubmit()}>
              Save
            </button>
          </div>
        </CardBody>

        <Modal
          open={openCheck}
          disablePortal
          disableEnforceFocus
          disableAutoFocus
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="justify-content-center d-flex align-items-center mt-1">
                <span style={{ fontSize: "30px" }}>Change Password</span>
              </div>
              <div className="modal-body">
                <Formik
                  initialValues={{
                    password: "",
                    newpassword: "",
                  }}
                  validate={(values) => {
                    let errors = {};

                    if (!values.password)
                      errors.password = "Password is required.";

                    if (!values.newpassword)
                      errors.newpassword = "New password is required.";

                    //check if my values have errors
                    return errors;
                  }}
                  onSubmit={handleSubmitPassword}
                  render={(formProps) => {
                    return (
                      <Form className="col">
                        <div className="row">
                          <span
                            style={{
                              fontSize: "20px",
                              float: "left",
                              width: "40%",
                            }}
                          >
                            Password :
                          </span>

                          <Field
                            type="password"
                            name="password"
                            className="form-control"
                            placeholder="Password"
                            style={{
                              height: "38px",
                              width: "530px",
                              backgroundColor: "#F4F6F9",
                              borderRadius: "5px",
                              borderWidth: "0",
                            }}
                          />
                        </div>
                        <div className="form-inline">
                          <ErrorMessage className="colorError" name="password">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </div>

                        <div className="row mt-1">
                          <span
                            style={{
                              fontSize: "20px",
                              float: "left",
                              width: "40%",
                            }}
                          >
                            New Password :{" "}
                          </span>
                          <Field
                            type="password"
                            name="newpassword"
                            className="form-control"
                            placeholder="Password"
                            style={{
                              height: "38px",
                              width: "530px",
                              backgroundColor: "#F4F6F9",
                              borderRadius: "5px",
                              borderWidth: "0",
                            }}
                          />
                        </div>

                        <div className="form-inline">
                          <ErrorMessage
                            className="colorError"
                            name="newpassword"
                          >
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </div>

                        <div className="modal-footer">
                          <button
                            className="btn btn-primary"
                            type="submit"
                            disabled={formProps.isSubmitting}
                          >
                            Confirm
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => handleClose()}
                          >
                            Close
                          </button>
                        </div>
                      </Form>
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </Modal>
      </Card>
    </>
  );
};
