import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../_metronic/_partials/controls";
import BootstrapTable from "react-bootstrap-table-next";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../_metronic/_helpers";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useSubheader } from "../../_metronic/layout";
import axios from "axios";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Swal from "sweetalert2";
import { baseURL } from "../service/API.js";
import Pagination from 'react-pagination-library'
import 'react-pagination-library/build/css/index.css'

export const ProductCategory = () => {
  const suhbeader = useSubheader();
  const allData = [];
  const [productCategory, setProductCategory] = useState([]);
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [lastPage, setLastPage] = useState(10)
  suhbeader.setTitle("Product Category");
  const history = useHistory();

  useEffect(() => {
    getProductCategory(1,10);
  }, []);

  const getProductCategory = (page,limit) => {
    axios
      .get(`${baseURL}/productCategory?page=${page}&limit=${limit}&is_delete=false${search !== undefined && search !== '' ? "&search=" + search : ''}`)
      .then((res) => {
        res.data.data.map((data, index) => {
          data = {
            id: data.id,
            name: data.name,
            detail: data.detail,
            created_at:
              data.created_at !== null
                ? moment(data.created_at).format("YYYY-MM-DD")
                : null,
          };

          allData.push(data);
        });

        var number = Number(res.data.total) / Number(res.data.limit)
        setLastPage(Math.ceil(number))
        setProductCategory(allData);
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const getProductCategoryPage = async p => {
    await getProductCategory(p, limit)
  }

  const getProductCategoryLimit = async p => {
    await setLimit(p)
    await getProductCategory(page, p)
  }

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "detail",
      text: "Detail",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "created_at",
      text: "Create date",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      text: "Action",
      dataField: "id",
      formatter: (id, row, rowIndex) => {
        return (
          <div>
            <button
              type="button"
              className="btn btn-primary mr-2"
              onClick={() => editItem({ id, row, rowIndex })}
            >
              Edit
            </button>

            <button
              type="button"
              className="btn btn-primary"
              onClick={() => deleteItem({ row, rowIndex })}
            >
              Delete
            </button>
          </div>
        );
      },
    },
  ];

  const editItem = (data) => {
    history.push("/EditProductCategory", data);
  };

  const deleteItem = (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this Product Category !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.value) {
        axios
          .delete(`${baseURL}/productCategory/${data.row.id}`)
          .then((res) => {
            Swal.fire(
              "Deleted!",
              "Your Product Category has been deleted.",
              "success"
            );
            getProductCategory(page,limit);
          })
          .catch((err) => {
            Swal.fire(
              "Deleted!",
              "Your Product Category has been fail to deleted.",
              "error"
            );
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your Product Category is safe :)", "error");
      }
    });
  };

  const [search, setSearch] = useState();
  const handleSearch = () => {
    getProductCategory(page,limit);
  };

  const onChanges = e => {
    setLimit(e.target.value)
    getProductCategoryLimit(e.target.value)
  }

  const pagination = paginationFactory({
    page: lastPage,
    alwaysShowAllBtns: true,
    withFirstAndLast: false,
    pageListRenderer: ({ pages, onPageChange }) => {
      return (
        <div className="dataTables_length" id="datatable-basic_length">
          <label>
            <Pagination
              currentPage={page}
              totalPages={lastPage}
              changeCurrentPage={async numPage => {
                onPageChange(numPage)
                await setPage(numPage)
                await getProductCategoryPage(numPage)
              }}
              theme="bottom-border form form-control-sm"
            />
          </label>
        </div>
      )
    },
    sizePerPageRenderer: ({
      options,
      currSizePerPage,
      onSizePerPageChange
    }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={e => {
                onChanges(e)
                onSizePerPageChange(e.target.value)
              }}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          }
        </label>
      </div>
    )
  })


  return (
    <Card>
      <CardHeader title="Product Category">
        <CardHeaderToolbar>
          <Link to="/CreateProductCategory">
            <button type="button" className="btn btn-primary">
              + Create
            </button>
          </Link>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="row form-inline mb-2">
          <div className="col-lg-4 col-md-4 col-sm-4">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Text Search"
                style={{ width: "100%" }}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-2">
            <div className="form-group">
              <button
                type="button"
                id="button"
                className="btn btn-primary"
                style={{ width: "100%" }}
                onClick={() => handleSearch()}
              >
                Search
              </button>
            </div>
          </div>
        </div>

        <BootstrapTable
          wrapperClasses="table-responsive"
          bordered={false}
          classes="table table-head-custom table-vertical-center overflow-hidden"
          bootstrap4
          keyField="id"
          pagination={pagination}
          data={productCategory === undefined ? [] : productCategory}
          columns={columns}
        >
          <PleaseWaitMessage entities={productCategory} />
          <NoRecordsFoundMessage entities={productCategory} />
        </BootstrapTable>
      </CardBody>
    </Card>
  );
};
