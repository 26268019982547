import axios from "axios";
import { baseURL } from "../../../service/API";

export const LOGIN_URL = `${baseURL}/auth/login`;
export const ME_URL = `${baseURL}/auth/me`;

export function login(email, password) {
  return axios.post(LOGIN_URL, { email, password });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.post(ME_URL);
}
