import React, { useState, useEffect } from "react";
import { useSubheader } from "../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../_metronic/_partials/controls";
import { useHistory } from "react-router-dom";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import axios from "axios";
import { baseURL, imageURL } from "../../service/API.js";
import Switch from "@material-ui/core/Switch";
import Swal from "sweetalert2";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../_metronic/_helpers";
import paginationFactory from "react-bootstrap-table2-paginator";
import Select from "react-select";
import { Link } from "react-router-dom";
import CropperImage from "./CropperImage";
import "./component.css";
export const CreateWorkCase = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Create Work Case");
  const history = useHistory();

  const [work_type_id, setWork_type_id] = useState();
  const [customer_id, setCustomer_id] = useState();
  const [work_time_period, setWork_time_period] = useState();
  const [work_date, setWork_date] = useState();
  const [address_name, setAddress_name] = useState();
  const [address_latitude, setAddress_latitude] = useState();
  const [address_longitude, setAddress_longitude] = useState();
  const [address_start_img, setAddress_start_img] = useState();
  const [nameImageAddress_start_img, setNameImageAddress_start_img] = useState(
    ""
  );
  const [address_end_img, setAddress_end_img] = useState();
  const [nameImageAddress_end_img, setNameImageAddress_end_img] = useState("");

  const [customer_sign_img, setCustomer_sign_img] = useState();
  const [nameImageCustomer_sign_img, setNameImageCustomer_sign_img] = useState(
    ""
  );

  const [work_detail, setWork_detail] = useState();
  const [product_category_id, setProduct_category_id] = useState();
  const [product_type_id, setProduct_type_id] = useState();
  const [description, setDescription] = useState();
  const [service_charge, setService_charge] = useState();
  const [status, setStatus] = useState();
  const [workCaseType, setWorkCaseTypes] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [productCategory, setProductCategory] = useState([]);
  const [productType, setProductType] = useState([]);
  const allDataType = [];
  const allDataCustomer = [];
  const allDataCategory = [];
  const allDataProductType = [];

  const [stateAddress_start_img, setStateAddress_start_img] = useState({
    previewAddress_start_img: null,
    rawAddress_start_img: null,
    imageURLAddress_start_img: null,
  });

  const [stateAddress_end_img, setStateAddress_end_img] = useState({
    previewAddress_end_img: null,
    rawAddress_end_img: null,
    imageURLAddress_end_img: null,
  });

  const [stateCustomer_sign_img, setStateCustomer_sign_img] = useState({
    previewCustomer_sign_img: null,
    rawCustomer_sign_img: null,
    imageURLCustomer_sign_img: null,
  });

  const [detail, setDetail] = useState();
  const [name, setName] = useState();

  const AlertError = (message) => {
    Swal.fire("Work Case!", message, "error");
  };

  useEffect(() => {
    getProductCategory();
    getWorkCaseType();
    getCustomer();
  }, []);

  const getCustomer = (search) => {
    axios
      .get(`${baseURL}/customer`)
      .then((res) => {
        res.data.map((data, index) => {
          data = {
            id: data.id,
            full_name: data.full_name,
            created_at:
              data.created_at !== null
                ? moment(data.created_at).format("YYYY-MM-DD")
                : null,
          };

          allDataCustomer.push(data);
        });
        setCustomer(allDataCustomer);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getWorkCaseType = (search) => {
    axios
      .get(`${baseURL}/workCaseType`)
      .then((res) => {
        res.data.map((data, index) => {
          data = {
            id: data.id,
            name: data.name,
            created_at:
              data.created_at !== null
                ? moment(data.created_at).format("YYYY-MM-DD")
                : null,
          };

          allDataType.push(data);
        });
        setWorkCaseTypes(allDataType);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getProductCategory = (search) => {
    axios
      .get(`${baseURL}/productCategory`)
      .then((res) => {
        res.data.map((data, index) => {
          data = {
            id: data.id,
            name: data.name,
            detail: data.detail,
            created_at:
              data.created_at !== null
                ? moment(data.created_at).format("YYYY-MM-DD")
                : null,
          };

          allDataCategory.push(data);
        });
        setProductCategory(allDataCategory);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const getProductType = (search) => {
  //   axios
  //     .get(`${baseURL}/productType`)
  //     .then((res) => {
  //       res.data.map((data, index) => {
  //         data = {
  //           id: data.id,
  //           name: data.name,
  //           detail: data.detail,
  //           created_at:
  //             data.created_at !== null
  //               ? moment(data.created_at).format("YYYY-MM-DD")
  //               : null,
  //         };

  //         allDataProductType.push(data);
  //       });
  //       setProductType(allDataProductType);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const onSubmit = async (data) => {
    if (work_type_id === undefined || work_type_id === "") {
      return AlertError("please insert work type.");
    }

    if (customer_id === undefined || customer_id === "") {
      return AlertError("please insert customer.");
    }

    if (work_time_period === undefined || work_time_period === "") {
      return AlertError("please select work time period.");
    }

    if (work_date === undefined || work_date === "") {
      return AlertError("please select work date.");
    }

    if (address_name === undefined || address_name === "") {
      return AlertError("please select address name.");
    }

    if (address_latitude === undefined || address_latitude === "") {
      return AlertError("please select address latitude.");
    }

    if (address_longitude === undefined || address_longitude === "") {
      return AlertError("please select address longitude.");
    }

    if (address_start_img === undefined || address_start_img === "") {
      return AlertError("please select address start img.");
    }

    if (address_end_img === undefined || address_end_img === "") {
      return AlertError("please select address end img.");
    }

    if (product_category_id === undefined || product_category_id === "") {
      return AlertError("please select product category.");
    }

    if (product_type_id === undefined || product_type_id === "") {
      return AlertError("please select product type.");
    }

    if (description === undefined || description === "") {
      return AlertError("please select description.");
    }

    if (service_charge === undefined || service_charge === "") {
      return AlertError("please select service charge.");
    }

    if (customer_sign_img === undefined || customer_sign_img === "") {
      return AlertError("please select customer sign img.");
    }

    if (status === undefined || status === "") {
      return AlertError("please select status.");
    }

    var events = {
      work_type_id: work_type_id,
      customer_id: customer_id,
      work_time_period,
      work_date,
      address_name,
      address_latitude,
      address_longitude,
      address_start_img,
      address_end_img,
      work_detail,
      product_category_id: product_category_id,
      product_type_id: product_type_id,
      description,
      service_charge,
      customer_sign_img,
      status,
    };
    console.log(events);
    axios
      .post(`${baseURL}/workCase`, events)
      .then((res) => {
        history.goBack();
      })
      .catch((err) => {
        Swal.fire(
          "Create !",
          "Your can not update workCase. !! " + err,
          "error"
        );
      });
  };

  const handleChangeWordType = (event) => {
    setWork_type_id(event.target.value);
  };

  const handleChangeCustomer = (event) => {
    setCustomer_id(event.target.value);
  };

  const handleChangeWorkTimePeriod = (event) => {
    setWork_time_period(event.target.value);
  };

  const handleChangeAddress_start_img = async (e) => {
    if (e.target.files.length) {
      e.preventDefault();
      let file = e.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function(e) {
        var image = new Image();
        image.src = e.target.result;
        image.onload = function() {
          var height = this.height;
          var width = this.width;
          if (height < 512 || width < 512) {
            Swal.fire(
              "Upload !",
              "At least you can upload a 512*512 photo size. !! ",
              "error"
            );
            e.target.value = null;
            return false;
          } else {
            setStateAddress_start_img({
              rawAddress_start_img: file,
              imageURLAddress_start_img: e.target.result,
            });
            e.target.value = null;
            return true;
          }
        };
      };
    }
  };
  const handleImageUploadAddress_start_img = async (file) => {
    var formdata = new FormData();
    formdata.append("image", file, URL.createObjectURL(file));
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(`${baseURL}/upload/`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let res = JSON.parse(result);
        console.log(res);
        setStateAddress_start_img({
          previewAddress_start_img: null,
          rawAddress_start_img: null,
          imageURLAddress_start_img: null,
        });
        setAddress_start_img(`${imageURL}/${res.filename}`);
        setNameImageAddress_start_img(res.filename);
      })
      .catch((error) => console.log("error", error));
  };

  const removeImageAddress_start_img = async () => {
    setStateAddress_start_img({
      previewAddress_start_img: null,
      rawAddress_start_img: null,
      imageURLAddress_start_img: null,
    });
    setAddress_start_img("");
  };

  const handleCancelUploadAddress_start_img = () => {
    setStateAddress_start_img({
      previewAddress_start_img: null,
      rawAddress_start_img: null,
      imageURLAddress_start_img: null,
    });
  };

  const handleChangeAddress_end_img = async (e) => {
    if (e.target.files.length) {
      e.preventDefault();
      let file = e.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function(e) {
        var image = new Image();
        image.src = e.target.result;
        image.onload = function() {
          var height = this.height;
          var width = this.width;
          if (height < 512 || width < 512) {
            Swal.fire(
              "Upload !",
              "At least you can upload a 512*512 photo size. !! ",
              "error"
            );
            e.target.value = null;
            return false;
          } else {
            setStateAddress_end_img({
              rawAddress_end_img: file,
              imageURLAddress_end_img: e.target.result,
            });
            e.target.value = null;
            return true;
          }
        };
      };
    }
  };
  const handleImageUploadAddress_end_img = async (file) => {
    var formdata = new FormData();
    formdata.append("image", file, URL.createObjectURL(file));
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(`${baseURL}/upload/`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let res = JSON.parse(result);
        setStateAddress_end_img({
          previewAddress_end_img: null,
          rawAddress_end_img: null,
          imageURLAddress_end_img: null,
        });
        setAddress_end_img(`${imageURL}/${res.filename}`);
        setNameImageAddress_end_img(res.filename);
      })
      .catch((error) => console.log("error", error));
  };

  const removeImageAddress_end_img = async () => {
    setStateAddress_end_img({
      previewAddress_end_img: null,
      rawAddress_end_img: null,
      imageURLAddress_end_img: null,
    });
    setAddress_end_img("");
  };

  const handleCancelUploadAddress_end_img = () => {
    setStateAddress_end_img({
      previewAddress_end_img: null,
      rawAddress_end_img: null,
      imageURLAddress_end_img: null,
    });
  };

  const handleChange = (event) => {
    setProduct_category_id(event.target.value);
    console.log(event.target.value);
    axios
      .get(`${baseURL}/productType/listProductCategory/${event.target.value}`)
      .then((res) => {
        res.data.map((data, index) => {
          data = {
            id: data.id,
            name: data.name,
          };

          allDataProductType.push(data);
        });
        setProductType(allDataProductType);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChangeProductType = (event) => {
    setProduct_type_id(event.target.value);
  };

  const handleChangeCustomer_sign_img = async (e) => {
    if (e.target.files.length) {
      e.preventDefault();
      let file = e.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function(e) {
        var image = new Image();
        image.src = e.target.result;
        image.onload = function() {
          var height = this.height;
          var width = this.width;
          if (height < 512 || width < 512) {
            Swal.fire(
              "Upload !",
              "At least you can upload a 512*512 photo size. !! ",
              "error"
            );
            e.target.value = null;
            return false;
          } else {
            setStateCustomer_sign_img({
              rawCustomer_sign_img: file,
              imageURLCustomer_sign_img: e.target.result,
            });
            e.target.value = null;
            return true;
          }
        };
      };
    }
  };
  const handleImageUploadCustomer_sign_img = async (file) => {
    var formdata = new FormData();
    formdata.append("image", file, URL.createObjectURL(file));
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(`${baseURL}/upload/`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let res = JSON.parse(result);
        setStateCustomer_sign_img({
          previewCustomer_sign_img: null,
          rawCustomer_sign_img: null,
          imageURLCustomer_sign_img: null,
        });
        setCustomer_sign_img(`${imageURL}/${res.filename}`);
        setNameImageCustomer_sign_img(res.filename);
      })
      .catch((error) => console.log("error", error));
  };

  const removeImageCustomer_sign_img = async () => {
    setStateCustomer_sign_img({
      previewCustomer_sign_img: null,
      rawCustomer_sign_img: null,
      imageURLCustomer_sign_img: null,
    });
    setCustomer_sign_img("");
  };

  const handleCancelUploadCustomer_sign_img = () => {
    setStateCustomer_sign_img({
      previewCustomer_sign_img: null,
      rawCustomer_sign_img: null,
      imageURLCustomer_sign_img: null,
    });
  };
  return (
    <>
      <Card>
        <CardHeader title="Create Work Case"></CardHeader>
        <CardBody>
          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              Work type:{" "}
            </p>
            <select
              as="select"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={work_type_id}
              onChange={handleChangeWordType}
            >
              <option value="">Please select</option>
              {workCaseType &&
                workCaseType.map((result, index) => {
                  return (
                    <option key={index} value={result.id}>
                      {result.name}
                    </option>
                  );
                })}
            </select>
          </div>

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              Customer:{" "}
            </p>
            <select
              as="select"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={customer_id}
              onChange={handleChangeCustomer}
            >
              <option value="">Please select</option>
              {customer &&
                customer.map((result, index) => {
                  return (
                    <option key={index} value={result.id}>
                      {result.full_name}
                    </option>
                  );
                })}
            </select>
          </div>

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              Work time period:{" "}
            </p>
            <select
              as="select"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={work_time_period}
              onChange={handleChangeWorkTimePeriod}
            >
              <option value="">Please select</option>
              <option value="10:00 - 11:00">10:00 - 11:00</option>
              <option value="11:00 - 12:00">11:00 - 12:00</option>
              <option value="12:00 - 13:00">12:00 - 13:00</option>
              <option value="13:00 - 14:00">13:00 - 14:00</option>
              <option value="14:00 - 15:00">14:00 - 15:00</option>
              <option value="15:00 - 16:00">15:00 - 16:00</option>
              <option value="16:00 - 17:00">16:00 - 17:00</option>
            </select>
          </div>

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              Work date:{" "}
            </p>
            <TextField
              id="work_date"
              type="date"
              defaultValue="yyyy-MM-dd"
              style={{
                height: "38px",
                width: "185px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(data) =>
                setWork_date(
                  data.target.value === "NULL" ? null : data.target.value
                )
              }
            />
          </div>

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              Address name:{" "}
            </p>
            <input
              name="address_name"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              onChange={(address_name) =>
                setAddress_name(address_name.target.value)
              }
            />
          </div>

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              Address latitude:{" "}
            </p>
            <input
              name="address_latitude"
              type="number"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              onChange={(address_latitude) =>
                setAddress_latitude(address_latitude.target.value)
              }
            />
          </div>

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              Address longitude:{" "}
            </p>
            <input
              name="address_longitude"
              type="number"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              onChange={(address_longitude) =>
                setAddress_longitude(address_longitude.target.value)
              }
            />
          </div>

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              Address start image : <br></br>
              (512*512)
            </p>
            <input
              name="address_start_img"
              style={{
                height: "38px",
                width: "330px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              disabled
              value={address_start_img}
            />

            <span className="btn btn-primary btn-file wbt">
              เลือก / แก้ไข
              <input type="file" onChange={handleChangeAddress_start_img} />
            </span>

            <button
              type="button"
              className="btn btn-danger wbt"
              onClick={removeImageAddress_start_img}
            >
              ลบ
            </button>
          </div>

          <div
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              width: "50%",
            }}
          >
            {stateAddress_start_img.previewAddress_start_img ? (
              <img
                style={{
                  width: "300px",
                  height: "300px",
                }}
                src={stateAddress_start_img.previewAddress_start_img}
              />
            ) : address_start_img ? (
              <img
                style={{
                  width: "300px",
                  height: "300px",
                }}
                src={address_start_img}
              />
            ) : null}
          </div>
          {stateAddress_start_img.imageURLAddress_start_img ? (
            <CropperImage
              image={stateAddress_start_img.imageURLAddress_start_img}
              handleCancel={handleCancelUploadAddress_start_img}
              handleUpload={handleImageUploadAddress_start_img}
              scale={1}
            />
          ) : (
            ""
          )}

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              Address end image : <br></br>
              (512*512)
            </p>
            <input
              name="address_end_img"
              style={{
                height: "38px",
                width: "330px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              disabled
              value={address_end_img}
            />

            <span className="btn btn-primary btn-file wbt">
              เลือก / แก้ไข
              <input type="file" onChange={handleChangeAddress_end_img} />
            </span>

            <button
              type="button"
              className="btn btn-danger wbt"
              onClick={removeImageAddress_end_img}
            >
              ลบ
            </button>
          </div>

          <div
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              width: "50%",
            }}
          >
            {stateAddress_end_img.previewAddress_end_img ? (
              <img
                style={{
                  width: "300px",
                  height: "300px",
                }}
                src={stateAddress_end_img.previewAddress_end_img}
              />
            ) : address_end_img ? (
              <img
                style={{
                  width: "300px",
                  height: "300px",
                }}
                src={address_end_img}
              />
            ) : null}
          </div>
          {stateAddress_end_img.imageURLAddress_end_img ? (
            <CropperImage
              image={stateAddress_end_img.imageURLAddress_end_img}
              handleCancel={handleCancelUploadAddress_end_img}
              handleUpload={handleImageUploadAddress_end_img}
              scale={1}
            />
          ) : (
            ""
          )}

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              Work detail:{" "}
            </p>
            <input
              name="work_detail"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              onChange={(work_detail) =>
                setWork_detail(work_detail.target.value)
              }
            />
          </div>

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              Product Category:{" "}
            </p>
            <select
              as="select"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={product_category_id}
              onChange={handleChange}
            >
              <option value="">Please select</option>
              {productCategory &&
                productCategory.map((result, index) => {
                  return (
                    <option key={index} value={result.id}>
                      {result.name}
                    </option>
                  );
                })}
            </select>
          </div>

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              Product type:{" "}
            </p>
            <select
              as="select"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={product_type_id}
              onChange={handleChangeProductType}
            >
              <option value="">Please select</option>
              {productType &&
                productType.map((result, index) => {
                  return (
                    <option key={index} value={result.id}>
                      {result.name}
                    </option>
                  );
                })}
            </select>
          </div>

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              Description:{" "}
            </p>
            <input
              name="description"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              onChange={(description) =>
                setDescription(description.target.value)
              }
            />
          </div>

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              Service charge:{" "}
            </p>
            <input
              name="service_charge"
              type="number"
              step="0.01"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={service_charge}
              onChange={(service_charge) => {
                setService_charge(service_charge.target.value);
              }}
            />
          </div>

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              Customer sign image : <br></br>
              (512*512)
            </p>
            <input
              name="customer_sign_img"
              style={{
                height: "38px",
                width: "330px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              disabled
              value={customer_sign_img}
            />

            <span className="btn btn-primary btn-file wbt">
              เลือก / แก้ไข
              <input type="file" onChange={handleChangeCustomer_sign_img} />
            </span>

            <button
              type="button"
              className="btn btn-danger wbt"
              onClick={removeImageCustomer_sign_img}
            >
              ลบ
            </button>
          </div>

          <div
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              width: "50%",
            }}
          >
            {stateCustomer_sign_img.previewCustomer_sign_img ? (
              <img
                style={{
                  width: "300px",
                  height: "300px",
                }}
                src={stateCustomer_sign_img.previewCustomer_sign_img}
              />
            ) : customer_sign_img ? (
              <img
                style={{
                  width: "300px",
                  height: "300px",
                }}
                src={customer_sign_img}
              />
            ) : null}
          </div>
          {stateCustomer_sign_img.imageURLCustomer_sign_img ? (
            <CropperImage
              image={stateCustomer_sign_img.imageURLCustomer_sign_img}
              handleCancel={handleCancelUploadCustomer_sign_img}
              handleUpload={handleImageUploadCustomer_sign_img}
              scale={1}
            />
          ) : (
            ""
          )}

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              Status:{" "}
            </p>
            <input
              name="status"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              onChange={(status) => setStatus(status.target.value)}
            />
          </div>

          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <div
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            ></div>
            <Link to="/WorkCase">
              <button type="button" className="btn btn-secondary wbt mr-5">
                Cancel
              </button>
            </Link>
            <button className="btn btn-primary" onClick={() => onSubmit()}>
              Save
            </button>
          </div>
        </CardBody>
      </Card>
    </>
  );
};
